import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import type { CookieConsentConfig } from "vanilla-cookieconsent";
import { agreedToCookiesScriptConsent } from "@/utils/cookie";

export default defineNuxtPlugin(async () => {
  const { initialize } = useGtag();
  const config = generateCookieConfig(initialize);
  await CookieConsent.run(config);

  return {
    provide: {
      CC: CookieConsent,
    },
  };
});

const generateCookieConfig = (initialize: () => void): CookieConsentConfig => {
  return {
    guiOptions: {
      consentModal: {
        layout: "box inline",
        position: "bottom left",
      },
      preferencesModal: {
        layout: "box",
        equalWeightButtons: false,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        readOnly: true,
        enabled: true,
        services: {
          recaptcha: {
            label: "Google reCAPTCHA",
          },
        },
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^(_ga|_gid)/,
            },
          ],
        },
        services: {
          gtm: {
            label: "Google Tag Manager",
            onAccept: () => {
              agreedToCookiesScriptConsent.accept();
            },
          },
          ga4: {
            label: "Google Analytics",
            onAccept: () => {
              initialize();
            },
          },
        },
      },
    },

    language: {
      default: "en",

      translations: {
        en: {
          consentModal: {
            title: "We value your privacy",
            description:
              `We use cookies to enhance your browsing experience, serve personalised content, and analyse our traffic. By clicking "Accept all", you consent to our use of cookies in accordance with our <a href="${ROUTES.privacyPolicy}" target="_blank" >Privacy Policy</a>.`,
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage preferences",

          },
          preferencesModal: {
            title: "Privacy Preferences Centre",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Save preferences",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  "These cookies are essential for the website to function properly. They enable core functionality such as security, form submission, and proper website operation.",
                linkedCategory: "necessary",
              },
              {
                title: "Analytics Cookies",
                description:
                  "These cookies help us understand how visitors interact with our website. This information is used to improve our website and services.",
                linkedCategory: "analytics",
              },
            ],
          },
        },
      },
    },
  };
};
