<script setup lang="ts">
import { agreedToCookiesScriptConsent } from "@/utils/cookie";
import CustomLoader from "@/components/CustomLoader.vue";
import type { H3Error } from "h3";
import "reflect-metadata";

const config = useRuntimeConfig();
const route = useRoute();

useScriptGoogleTagManager({
  id: config.public.googleTagManagerId,
  scriptOptions: {
    trigger: agreedToCookiesScriptConsent,
  },
});

useHead({
  titleTemplate: "%s | Hirable",
});

useSeoMeta({
  author: () => config.public.appName,
  ogImage: `${config.public.appUrl}/hirable.jpg`,
  ogType: "website",
  ogSiteName: () => config.public.appName,
  ogUrl: () => `${config.public.appUrl}${route.path}`,
  twitterCard: "summary_large_image",
  twitterImage: `${config.public.appUrl}/hirable.jpg`,
  twitterImageAlt: () => config.public.appName,
  twitterSite: "@HirableSa",
  twitterCreator: "@HirableSa",
});
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage class="dark:text-white" />
    </NuxtLayout>
    <Notify />
    <CustomLoader />
  </div>
</template>
