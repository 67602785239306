import { useRootStore } from "@/store/root";
import type { Notify } from "@/types";

export function showNotifyError(desc: string | Error | unknown, title = "Error") {
  const error = typeof desc === "string" ? new Error(desc) : desc;
  const notification: Notify = {
    title,
    desc: getErrorMessage(error),
    textColour: "text-danger",
    borderColour: "border-danger",
    icon: "fa6-solid:circle-xmark",
  };
  return showNotify(notification);
}

export function showNotifySuccess(desc: string, title = "Success") {
  const notification: Notify = {
    title,
    desc,
    textColour: "text-primary",
    borderColour: "border-primary",
    icon: "fa6-solid:circle-check",
  };
  return showNotify(notification);
}

export function showNotifyWarning(desc: string, title = "Warning") {
  const notification: Notify = {
    title,
    desc,
    textColour: "text-warning",
    borderColour: "border-warning",
    icon: "fa6-solid:circle-exclamation",
  };
  return showNotify(notification);
}

function showNotify(notification: Notify) {
  const rootStore = useRootStore();
  return rootStore.toggleShowNotify(notification);
}
