import type { PiniaPluginContext } from "pinia";

function ResetPiniaPlugin({ store, pinia }: PiniaPluginContext) {
  store.$reset = () => {
    store.$dispose();
    delete pinia.state.value[store.$id];
  };
}

const ResetPlugin: any = ({ $pinia }: { $pinia: any }) => {
  $pinia.use(ResetPiniaPlugin);
};

export default ResetPlugin;
