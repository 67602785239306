import { defineNuxtPlugin } from "#app";
import { Loader, type LoaderOptions } from "@googlemaps/js-api-loader";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const loaderOptions: LoaderOptions = {
    apiKey: config.public.googleMapsApiKey,
    id: "__googleMapsScriptId",
    version: "weekly",
    libraries: ["geometry"],
  };

  const loader = new Loader(loaderOptions);

  return {
    provide: {
      gMapLoader: () => loader,
    },
  };
});
