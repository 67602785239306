import { defineNuxtPlugin } from "#app";
import { abilitiesPlugin } from '@casl/vue';

import { createMongoAbility } from '@casl/ability';

export const initialAbilities = createMongoAbility([
  {
    action: 'all',
    subject: 'all',
    inverted: true
  }
])

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(abilitiesPlugin, initialAbilities);
});
