import { default as action_45reference3Qjvypm8e6Meta } from "/vercel/path0/pages/action-reference.vue?macro=true";
import { default as actionhWfIiHVoCQMeta } from "/vercel/path0/pages/action.vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as featuresLse7A5xVDGMeta } from "/vercel/path0/pages/candidates/features.vue?macro=true";
import { default as indexXOzMy3lhAAMeta } from "/vercel/path0/pages/candidates/index.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as create_45memberMV1boHcO0yMeta } from "/vercel/path0/pages/create-member.vue?macro=true";
import { default as create_45ownerYCD4Y3wIAsMeta } from "/vercel/path0/pages/create-owner.vue?macro=true";
import { default as indexdV9FWWUuUFMeta } from "/vercel/path0/pages/dashboard/applications/index.vue?macro=true";
import { default as indexA8mkq8srhXMeta } from "/vercel/path0/pages/dashboard/archived-jobs/index.vue?macro=true";
import { default as _91id_93gOAaBmL4OuMeta } from "/vercel/path0/pages/dashboard/archived-jobs/repost/[id].vue?macro=true";
import { default as _91id_93ENfNdSZeo0Meta } from "/vercel/path0/pages/dashboard/archived-jobs/view/[id].vue?macro=true";
import { default as index1T8LT2NP01Meta } from "/vercel/path0/pages/dashboard/candidates/index.vue?macro=true";
import { default as index1L80cTfbFhMeta } from "/vercel/path0/pages/dashboard/chat/index.vue?macro=true";
import { default as indexlQA3dFMsunMeta } from "/vercel/path0/pages/dashboard/home/index.vue?macro=true";
import { default as createaC9Vvmaxj3Meta } from "/vercel/path0/pages/dashboard/jobs/create.vue?macro=true";
import { default as _91id_93rxCn6Tex31Meta } from "/vercel/path0/pages/dashboard/jobs/edit/[id].vue?macro=true";
import { default as indexZdqyYkiesAMeta } from "/vercel/path0/pages/dashboard/jobs/index.vue?macro=true";
import { default as _91id_93QD9hqTJGPmMeta } from "/vercel/path0/pages/dashboard/jobs/invite/[id].vue?macro=true";
import { default as _91id_93GZk4rMdi41Meta } from "/vercel/path0/pages/dashboard/jobs/view/[id].vue?macro=true";
import { default as indexzhdWy4k05PMeta } from "/vercel/path0/pages/dashboard/payments/index.vue?macro=true";
import { default as account89M4I59mRnMeta } from "/vercel/path0/pages/dashboard/settings/account.vue?macro=true";
import { default as billingEIJdN4IBPcMeta } from "/vercel/path0/pages/dashboard/settings/billing.vue?macro=true";
import { default as companyfSmyA8v9zpMeta } from "/vercel/path0/pages/dashboard/settings/company.vue?macro=true";
import { default as teamMdYlVVt4scMeta } from "/vercel/path0/pages/dashboard/settings/team.vue?macro=true";
import { default as settingsiT0n0ZleJjMeta } from "/vercel/path0/pages/dashboard/settings.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as browse_45candidates1SyhQ5hzkeMeta } from "/vercel/path0/pages/employers/browse-candidates.vue?macro=true";
import { default as featuresd6C8DepatZMeta } from "/vercel/path0/pages/employers/features.vue?macro=true";
import { default as indexBvO5URUDWLMeta } from "/vercel/path0/pages/employers/index.vue?macro=true";
import { default as pricingSeZziS9dlUMeta } from "/vercel/path0/pages/employers/pricing.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as how_45to_45delete_45accountBm46sZ84k1Meta } from "/vercel/path0/pages/how-to-delete-account.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invalid_45linkYeDGlOdWa0Meta } from "/vercel/path0/pages/invalid-link.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as terms_45and_45conditions0ynQwIk1xRMeta } from "/vercel/path0/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "action-reference",
    path: "/action-reference",
    meta: action_45reference3Qjvypm8e6Meta || {},
    component: () => import("/vercel/path0/pages/action-reference.vue")
  },
  {
    name: "action",
    path: "/action",
    component: () => import("/vercel/path0/pages/action.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "candidates-features",
    path: "/candidates/features",
    meta: featuresLse7A5xVDGMeta || {},
    component: () => import("/vercel/path0/pages/candidates/features.vue")
  },
  {
    name: "candidates",
    path: "/candidates",
    meta: indexXOzMy3lhAAMeta || {},
    component: () => import("/vercel/path0/pages/candidates/index.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactJnsDpYVejzMeta || {},
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "create-member",
    path: "/create-member",
    meta: create_45memberMV1boHcO0yMeta || {},
    component: () => import("/vercel/path0/pages/create-member.vue")
  },
  {
    name: "create-owner",
    path: "/create-owner",
    meta: create_45ownerYCD4Y3wIAsMeta || {},
    component: () => import("/vercel/path0/pages/create-owner.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-applications",
    path: "applications",
    component: () => import("/vercel/path0/pages/dashboard/applications/index.vue")
  },
  {
    name: "dashboard-archived-jobs",
    path: "archived-jobs",
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/index.vue")
  },
  {
    name: "dashboard-archived-jobs-repost-id",
    path: "archived-jobs/repost/:id()",
    meta: _91id_93gOAaBmL4OuMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/repost/[id].vue")
  },
  {
    name: "dashboard-archived-jobs-view-id",
    path: "archived-jobs/view/:id()",
    component: () => import("/vercel/path0/pages/dashboard/archived-jobs/view/[id].vue")
  },
  {
    name: "dashboard-candidates",
    path: "candidates",
    component: () => import("/vercel/path0/pages/dashboard/candidates/index.vue")
  },
  {
    name: "dashboard-chat",
    path: "chat",
    component: () => import("/vercel/path0/pages/dashboard/chat/index.vue")
  },
  {
    name: "dashboard-home",
    path: "home",
    component: () => import("/vercel/path0/pages/dashboard/home/index.vue")
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    meta: createaC9Vvmaxj3Meta || {},
    component: () => import("/vercel/path0/pages/dashboard/jobs/create.vue")
  },
  {
    name: "dashboard-jobs-edit-id",
    path: "jobs/edit/:id()",
    component: () => import("/vercel/path0/pages/dashboard/jobs/edit/[id].vue")
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/vercel/path0/pages/dashboard/jobs/index.vue")
  },
  {
    name: "dashboard-jobs-invite-id",
    path: "jobs/invite/:id()",
    component: () => import("/vercel/path0/pages/dashboard/jobs/invite/[id].vue")
  },
  {
    name: "dashboard-jobs-view-id",
    path: "jobs/view/:id()",
    component: () => import("/vercel/path0/pages/dashboard/jobs/view/[id].vue")
  },
  {
    name: "dashboard-payments",
    path: "payments",
    component: () => import("/vercel/path0/pages/dashboard/payments/index.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    component: () => import("/vercel/path0/pages/dashboard/settings.vue"),
    children: [
  {
    name: "dashboard-settings-account",
    path: "account",
    component: () => import("/vercel/path0/pages/dashboard/settings/account.vue")
  },
  {
    name: "dashboard-settings-billing",
    path: "billing",
    component: () => import("/vercel/path0/pages/dashboard/settings/billing.vue")
  },
  {
    name: "dashboard-settings-company",
    path: "company",
    component: () => import("/vercel/path0/pages/dashboard/settings/company.vue")
  },
  {
    name: "dashboard-settings-team",
    path: "team",
    component: () => import("/vercel/path0/pages/dashboard/settings/team.vue")
  }
]
  }
]
  },
  {
    name: "employers-browse-candidates",
    path: "/employers/browse-candidates",
    meta: browse_45candidates1SyhQ5hzkeMeta || {},
    component: () => import("/vercel/path0/pages/employers/browse-candidates.vue")
  },
  {
    name: "employers-features",
    path: "/employers/features",
    meta: featuresd6C8DepatZMeta || {},
    component: () => import("/vercel/path0/pages/employers/features.vue")
  },
  {
    name: "employers",
    path: "/employers",
    meta: indexBvO5URUDWLMeta || {},
    component: () => import("/vercel/path0/pages/employers/index.vue")
  },
  {
    name: "employers-pricing",
    path: "/employers/pricing",
    meta: pricingSeZziS9dlUMeta || {},
    component: () => import("/vercel/path0/pages/employers/pricing.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue")
  },
  {
    name: "how-to-delete-account",
    path: "/how-to-delete-account",
    meta: how_45to_45delete_45accountBm46sZ84k1Meta || {},
    component: () => import("/vercel/path0/pages/how-to-delete-account.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invalid-link",
    path: "/invalid-link",
    meta: invalid_45linkYeDGlOdWa0Meta || {},
    component: () => import("/vercel/path0/pages/invalid-link.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditions0ynQwIk1xRMeta || {},
    component: () => import("/vercel/path0/pages/terms-and-conditions.vue")
  }
]