export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();
  const inMaintenance = config.public.inMaintenance;

  // TODO: Remove check for privacy policy
  if (inMaintenance && to.path !== ROUTES.maintenance && to.path !== ROUTES.privacyPolicy) {
    return navigateTo(ROUTES.maintenance);
  }

  if (!inMaintenance && to.path === ROUTES.maintenance) {
    return navigateTo(ROUTES.home);
  }
});
