<script setup lang="ts">
import { useRootStore } from "@/store/root";

const nuxtApp = useNuxtApp();

const route = useRoute();
const { appLoading } = storeToRefs(useRootStore());
const loading = ref(false);

const isDashboard = computed(
  () => route.path.includes("dashboard") || route.path.includes("create-owner") || route.path.includes("create-member")
);
const isNotConfirm = computed(() => !route.path.includes("confirm"));

nuxtApp.hook("page:start", () => {
  if (isNotConfirm.value) loading.value = true;
});

nuxtApp.hook("page:finish", () => {
  loading.value = false;
});

nuxtApp.hook("app:error", () => {
  loading.value = false;
});

nuxtApp.hook("vue:error", () => {
  loading.value = false;
});
</script>

<template>
  <div>
    <FullPageLoader v-if="appLoading || (isDashboard && loading)" />
    <NuxtLoadingIndicator v-else />
  </div>
</template>
