import type { H3Error } from "h3";

export default defineNuxtPlugin({
  name: "capture-error",
  dependsOn: ["sentry-client"],
  async setup(nuxtApp) {
    const { $sentryCaptureException } = useNuxtApp();

    const handleError = (err: any) => {
      const error = (err as Error | H3Error).message ?? (err as H3Error)?.statusMessage;
      showNotifyError(error);
      if ($sentryCaptureException && typeof $sentryCaptureException === "function") {
        $sentryCaptureException(err.data ?? err);
      }
    };

    nuxtApp.vueApp.config.errorHandler = (err) => {
      handleError(err);
    };
  },
});
