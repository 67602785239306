<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRootStore } from '@/store/root'

const rootStore = useRootStore()
const { notify } = storeToRefs(rootStore)

const closeNotify = () => rootStore.toggleShowNotify({ active: false })
</script>

<template>
  <div aria-live="assertive" style="z-index: 1002;" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition name="notify-slide-fade">
        <div
          v-if="notify.active"
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden border-1 border-solid"
          :class="notify.borderColour"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Icon :name="(notify.icon as string)" class="w-5 h-5" :class="notify.textColour" />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium" :class="notify.textColour">
                  {{ notify.title }}
                </p>
                <p class="mt-1 text-sm text-medium-gray">
                  {{ notify.desc }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <span class="sr-only">Close notification</span>
                <Icon
                  name="fa6-solid:xmark" class="w-4 h-4 cursor-pointer transition-all hover:rotate-180"
                  aria-hidden="true"
                  :class="notify.textColour"
                  @click="closeNotify"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
