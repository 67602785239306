import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/vercel/path0/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  "clear-store": () => import("/vercel/path0/middleware/clear-store.ts"),
  "credit-check": () => import("/vercel/path0/middleware/credit-check.ts"),
  "profile-check": () => import("/vercel/path0/middleware/profile-check.ts")
}